







































































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      isAdministrator: false,
    }
  },
  mounted() {
    this.$store.watch(() => {
      if (this.$store.state.app.userInfo !== null) {
        if (this.$store.state.app.userInfo.isAdministrator !== null) {
          this.isAdministrator = this.$store.state.app.userInfo.isAdministrator
        }
      }
    })
  },
})
